import { useElementSize } from '@kaliber/use-element-size'
import { useClientConfig } from '/machinery/ClientConfig'
import { extractLinkInfo } from '/machinery/extractLinkInfo'
import { PortableTextSimpleWithLinks } from '/features/buildingBlocks/PortableText'
import { HeadingLg } from '/features/buildingBlocks/Heading'
import { BoxWithRoundedCorners } from '/features/buildingBlocks/boxWithRoundedCorners/BoxWithRoundedCorners'
import { ButtonFatChevronRightPrimary } from '/features/buildingBlocks/Button'
import { Accordion } from '/features/buildingBlocks/Accordion'

import contentAndMapMarker from './content-and-map-marker.svg'

import styles from './ContentAndMap.css'

export function ContentAndMap({ title, content, contentColor, travelOptions, link, latLng }) {
  const { lng, lat, alt } = latLng
  const { ref: elementRef, size } = useElementSize()
  const src = useStaticMap({ size, lng, lat, alt })
  const accordionItems = React.useMemo(
    () => travelOptions?.items && travelOptions.items.map(({ blocks: content, ...x }) => ({ ...x, content })),
    [travelOptions]
  )

  const { href, label, target } = extractLinkInfo(link)

  return (
    <div className={styles.component} data-style-context={contentColor}>
      <div className={styles.layout}>
        <BoxWithRoundedCorners>
          <div className={styles.content}>
            <HeadingLg h={1} {...{ title }} />
            {content && <PortableTextSimpleWithLinks layoutClassName={styles.contentText} value={content} />}
            {travelOptions && <Accordion items={accordionItems} layoutClassName={styles.accordion} />}
            {href && label && <ButtonFatChevronRightPrimary {...{ href, target }} dataX='link'>{label}</ButtonFatChevronRightPrimary>}
          </div>
        </BoxWithRoundedCorners>

        <BoxWithRoundedCorners>
          <div className={styles.map} ref={elementRef}>
            {src && (
              <img className={styles.image} alt="" width={size.width} height={size.height} {...{ src }} />
            )}

            <img className={styles.marker} alt="" src={contentAndMapMarker} width="182" height="260" />
          </div>
        </BoxWithRoundedCorners>
      </div>
    </div>
  )
}

function useStaticMap({ size, lat, lng, alt }) {
  const [src, setSrc] = React.useState(null)
  const { mapbox } = useClientConfig()
  const coolDownRef = React.useRef(false)

  const mapboxSourceString = `https://api.mapbox.com/styles/v1/kaliber-nijntje-museum/cl6f3h5ei001e14jwgbqrmdky/static/${lng},${lat},${alt}/${Math.min(1280, Math.floor(size.width))}x${Math.min(1280, Math.floor(size.height))}@2x?access_token=${mapbox.token}`

  useTimeout(() => {
    coolDownRef.current = false
    setSrc(mapboxSourceString)
  }, 300)

  React.useEffect(
    () => {
      if (!coolDownRef.current) {
        coolDownRef.current = true
        setSrc(src)
      }
    },
    [src]
  )

  return src
}

export function useTimeout(callback, delay) {
  const savedCallbackRef = React.useRef(callback)

  React.useEffect(() => {
    savedCallbackRef.current = callback
  }, [callback])

  React.useEffect(() => {
    if (!delay && delay !== 0) {
      return
    }

    const id = setTimeout(() => savedCallbackRef.current(), delay)

    return () => clearTimeout(id)
  }, [delay])
}
