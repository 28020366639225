import { useElementSize }  from '@kaliber/use-element-size'
import { dataLayerEvent } from '/machinery/tracking/dataLayerEvent'
import { PortableTextSimple } from '/features/buildingBlocks/PortableText'
import { Icon } from '/features/buildingBlocks/Icon'
import { useSpring, animated } from 'react-spring'

import iconChevronDown from '/images/icons/chevron-down.raw.svg'
import styles from './Accordion.css'

export function Accordion({ items, layoutClassName = undefined }) {
  const [active, setActive] = React.useState(null)

  return (
    <div className={cx(styles.component, layoutClassName)}>
      {items.map(x => (
        <Item
          onActiveChange={() => handleActiveChangeForItem(x)}
          active={active === x}
          key={x._key}
          id={x._id}
          title={x.title}
          content={x.content} />
      ))}
    </div>
  )

  function handleActiveChangeForItem(item) {
    const newActive = active === item ? null : item
    setActive(newActive)
    dataLayerEvent('interaction_clicked', {
      interaction: {
        title: 'Accordion',
        type: 'click',
        subject: item.title,
        action: active === item ? 'close' : 'open'
      }
    })
  }
}

function Item({ id, title, content, active, onActiveChange }) {
  const { size: { height }, ref: elementRef } = useElementSize()

  const answerAnimation = useSpring({
    height: active ? height : 0,
    opacity: active ? 1 : 0,
    config: { tension: 300, friction: active ? 10 : 30 }
  })

  return (
    <div className={cx(styles.componentItem, active && styles.active)}>
      <h3 className={styles.title}>
        <button
          type='button'
          className={styles.trigger}
          onClick={onActiveChange}
          data-x='toggle-accordion-item'
          aria-controls={id}
          aria-expanded={active}
        >
          <span>{title}</span>
          <Icon layoutClassName={cx(styles.icon, active && styles.rotate)} icon={iconChevronDown} />
        </button>
      </h3>

      <animated.div
        className={styles.content}
        style={answerAnimation}
        aria-hidden={!active}
        {...{ id }}
      >
        <div ref={elementRef}>
          <div className={styles.inner}>
            {content && <Content {...{ content }} />}
          </div>
        </div>
      </animated.div>
    </div>
  )
}

function Content({ content }) {
  return Array.isArray(content)
    ? <PortableTextSimple value={content} />
    : content
}
